import React, { useEffect, useState } from "react";
import { useFetch } from "../../services/Requests/useFetch";
import { Col, Row, Button } from "react-bootstrap";

interface Assets {
  [assetName: string]: string;
}

interface Stops {
  [stopName: string]: Assets;
}

interface MissionData {
  [zoneName: string]: Stops;
}

const options: RequestInit = {
  method: "GET",
};

interface AssetTravelSelectProps {
  handleRunAsset: (zone: string, stop: string, asset: string) => void; // Accepts handleRunAsset as a prop
  Running: boolean;
}

const AssetTravelSelect: React.FC<AssetTravelSelectProps> = ({ handleRunAsset,Running }) => {
  const asset_data = useFetch<MissionData>("/api/missions/get_data", options);

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredAssets, setFilteredAssets] = useState<{
    zone: string;
    stop: string;
    asset: string;
  }[]>([]);
  const [selected, setSelected] = useState<{
    zone: string;
    stop: string;
    asset: string;
  } | null>(null);

  useEffect(() => {
    asset_data.get();

    return () => {
      asset_data.abortRequest && asset_data.abortRequest();
    };
  }, []);

  useEffect(() => {
    const {
      state: { status, data },
    } = asset_data;

    if (status === "fetched" && data) {
      if (data["zone0"]) {
        delete data["zone0"];
      }
      unfurlAssets(data);
    }
  }, [asset_data.state.data]);

  const unfurlAssets = (info: MissionData) => {
    const assetsList: { zone: string; stop: string; asset: string }[] = [];

    for (const zone in info) {
      for (const stop in info[zone]) {
        for (const asset in info[zone][stop]) {
          assetsList.push({ zone, stop, asset });
        }
      }
    }

    setFilteredAssets(assetsList);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const handleSelect = (item: { zone: string; stop: string; asset: string }) => {
    setSelected(item);
    console.log("Selected:", item);
  };

  const displayedAssets = filteredAssets.filter((item) =>
    item.asset.toLowerCase().includes(searchTerm)
  );

  return (
    <div style={{ width: "100%", maxWidth: "400px", margin: "auto", maxHeight:"40vh",overflowY: "auto",overflowX: "hidden" }}>
      <input
        type="text"
        placeholder="Search assets..."
        value={searchTerm}
        onChange={handleSearch}
        style={{
          width: "100%",
          padding: "10px",
          marginBottom: "10px",
          borderRadius: "5px",
          border: "1px solid #ccc",
          boxSizing: "border-box",
        }}
      />
      {selected && (
        <div
          style={{
            marginTop: "15px",
            padding: "10px",
            border: "1px solid #007BFF",
            borderRadius: "5px",
            backgroundColor: "#e9f5ff",
          }}
        >
          <h4>Selected Asset:</h4>
          <Row>
            <Col>
              <p>Zone: {selected.zone}</p>
              <p>Stop: {selected.stop}</p>
              <p>Asset: {selected.asset}</p>
            </Col>
            <Col>
              <Button
                onClick={() => handleRunAsset(selected.zone, selected.stop, selected.asset)}
                style={{ marginTop: "10px" }}
                disabled={Running}
              >
                Go Image Asset
              </Button>
            </Col>
          </Row>
        </div>
      )}
      <div
        style={{
          maxHeight: "80%",
          overflowY: "auto",
          border: "1px solid #ccc",
          borderRadius: "5px",
          padding: "10px",
          backgroundColor: "#f9f9f9",
        }}
      >
        <div style={{ display: "grid", gap: "10px", gridTemplateColumns: "1fr" }}>
          {displayedAssets.map((item, index) => (
            <div
              key={index}
              onClick={() => handleSelect(item)}
              style={{
                padding: "15px",
                border: "1px solid #ccc",
                borderRadius: "8px",
                backgroundColor: "#fff",
                cursor: "pointer",
                boxShadow: selected?.asset === item.asset ? "0 0 8px #007BFF" : "0 0 4px #ccc",
                transition: "box-shadow 0.3s",
              }}
            >
              <h5 style={{ margin: "0 0 5px 0" }}>{item.asset}</h5>
              <p style={{ margin: "0", fontSize: "0.9em", color: "#555" }}>
                {item.zone}/{item.stop}
              </p>
            </div>
          ))}
        </div>
      </div>

      
    </div>
  );
};

export default AssetTravelSelect;
