import { TopicInterface } from "../interfaces/Common";

export enum MessageTypes {
    BATTERY = "/battery_state",
    DIAGNOSTICS = "/diagnostics",
    // GPS = "/navsat/fix",
    GPS = "/gps_handler/fix",
    //Stamped CMD VEL
    TEST = "/cmd_vel_dashboard",
    // STAMPED = "/cmd_vel_override",
    // Unstamped CDM VEL
    // TEST = "/cmd_vel_teleop",
    STAMPED = "/cmd_vel_override",
    EDRIVING = "/cmd_vel",
    AUTOMATED = "/joy",
    COMPASS = "/gps/compass",
    WARN = "/keepout_zone_alert",
    FRONT_LIGHTS = "/lights/drive_front",
    REAR_LIGHTS = "/lights/drive_rear",
    ALL_LIGHTS = "/lights/all",
    GREEN_LIGHTS = "/lights/green",
    RED_LIGHTS = "/lights/red",
    BLUE_LIGHTS = "/lights/blue",
    RGB_LIGHTS = "/lights/rgb",
    STATE = "/robot_state",
    CARDINAL = "/gps/cardinal",
    ESTOP = "/kill_switch",
    FRONT_COLLISION = "/obstacle_front",
    REAR_COLLISION = "/obstacle_rear",
    ALL_COLLISIONS = "/collision_detector_state",
    ROBOT_STATE_TEST = "/robot_state",
};

export const ROBOT_STATE_TOPIC: TopicInterface = { 
    key : 'state',
    name : MessageTypes.ROBOT_STATE_TEST,
    type : "mercury_interfaces/msg/RobotStates",
    throttle : 1000
}

export const LIGHTS_TOPIC: TopicInterface = { 
    key : 'state',
    name : MessageTypes.ALL_LIGHTS,
    type : "std_msgs/msg/Uint8",
    throttle : 1000
}

export const RGB_TOPIC: TopicInterface = { 
    key : 'state',
    name : MessageTypes.RGB_LIGHTS,
    type : "std_msgs/msg/colorRGBA",
    throttle : 1000
}

export const STAMPED: TopicInterface = { 
    key : 'drive',
    name : MessageTypes.STAMPED,
    type : "geometry_msgs/msg/TwistStamped",
    throttle : 1000
}

export const FRONT_COLLISION: TopicInterface = { 
    key : 'collison',
    name : MessageTypes.FRONT_COLLISION,
    type : "std_msgs/msg/Bool",
    throttle : 1000
}

export const REAR_COLLISION: TopicInterface = { 
    key : 'collison',
    name : MessageTypes.REAR_COLLISION,
    type : "std_msgs/msg/Bool",
    throttle : 1000
}

export const ALL_COLLISIONS_TOPIC: TopicInterface = { 
    key : 'collison',
    name : MessageTypes.ALL_COLLISIONS,
    type : "nav2_msgs/msg/CollisionDetectorState",
    throttle : 1000
}

// # Name of configured polygons
// string[] polygons
// # List of detections for each polygon
// bool[] detections

export const ESTOP: TopicInterface = { 
    key : 'ESTOP',
    name : MessageTypes.ESTOP,
    type : "std_msgs/msg/Bool",
    throttle : 1000
}

export const STATE: TopicInterface = { 
    key : 'robot',
    name : MessageTypes.STATE,
    type : "std_msgs/msg/UInt8",
    throttle : 0
}

export const CARDINAL: TopicInterface = { 
    key : 'cardinal',
    name : MessageTypes.CARDINAL,
    type : "std_msgs/msg/String",
    throttle : 1500
}

export const FRONT_LIGHT_TOPIC: TopicInterface = { 
    key : 'lights',
    name : MessageTypes.FRONT_LIGHTS,
    type : "std_msgs/msg/Bool",
    throttle : 1500
}

export const REAR_LIGHT_TOPIC: TopicInterface = { 
    key : 'lights',
    name : MessageTypes.REAR_LIGHTS,
    type : "std_msgs/msg/Bool",
    throttle : 1500
}

export const RED_LIGHT_TOPIC: TopicInterface = { 
    key : 'lights',
    name : MessageTypes.RED_LIGHTS,
    type : "std_msgs/msg/Bool",
    throttle : 1500
}
export const GREEN_LIGHT_TOPIC: TopicInterface = { 
    key : 'lights',
    name : MessageTypes.GREEN_LIGHTS,
    type : "std_msgs/msg/Bool",
    throttle : 1500
}
export const BLUE_LIGHT_TOPIC: TopicInterface = { 
    key : 'lights',
    name : MessageTypes.BLUE_LIGHTS,
    type : "std_msgs/msg/Bool",
    throttle : 1500
}


export const WARN_TOPIC: TopicInterface = { 
    key : 'warn',
    name : MessageTypes.WARN,
    type : "std_msgs/msg/Int32",
    throttle : 1000
}

export const TEST_TOPIC: TopicInterface = { 
    key : 'test',
    name : MessageTypes.TEST,
    type : "geometry_msgs/msg/TwistStamped",
    throttle : 1000
}

export const BATTERY_TOPIC: TopicInterface = {
    key : 'battery',
    name : MessageTypes.BATTERY,
    type : "sensor_msgs/msg/BatteryState",
    throttle : 1500
};

export const DIAGNOSTICS_TOPIC: TopicInterface = {
    key : 'diagnostics',
    name : MessageTypes.DIAGNOSTICS,
    type : "diagnostic_msgs/msg/DiagnosticArray", 
    throttle : 5000
}

export const GPS_TOPIC: TopicInterface = {
    key : 'gps',
    name : MessageTypes.GPS,
    type : "sensor_msgs/msg/NavSatFix",
    throttle : 1500
};

export const COMPASS_TOPIC : TopicInterface = {
    key : 'compass',
    name : MessageTypes.COMPASS,
    type : "std_msgs/msg/UInt16",
    throttle : 1500
}

export const EDRIVING : TopicInterface = {
    key : 'EDRIVING',
    name : MessageTypes.EDRIVING,
    type : "geometry_msgs/msg/Twist",
    throttle : 1000
}

export const AUTOMATED_DRIVE : TopicInterface = {
    key : 'automated',
    name : MessageTypes.AUTOMATED,
    type : "sensor_msgs/msg/Joy"
}



