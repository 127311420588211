// Google maps 
export const GOOGLE_MAPS_API_KEY:string = "AIzaSyAwNihX6KvkTqOYrN3ecGgLiwXUx2N3wrs";

export const wsProtocol = window.location.protocol === 'https:' ? "wss://" : "ws://";


//ONVIF
export const ONVIFHOSTNAME:string = '60.52.113.13';
export const ONVIFPORT:number = 80;
export const ONVIFUSERNAME:string = 'rtc2go';
export const ONVIFPASSWORD:string = 'rtc2go';


//Colours
export const TEXT_COLOUR_LIGHT:string = "#E1E1E1";
export const TEXT_COLOUR_DARK:string = "#2D2D2D";
export const SUCCESS:string = "#28A745";
export const ERROR:string = "#F14944";
export const LIGHT_SUCCESS = "#28A745";
export const LIGHT_DISABLED = "#6C757D";
export const LIGHT_PRIMARY = "#0D6EFD";

//Video addresses 
export const TEST_STREAM_MAIN:string = "http://60.52.113.101:8080/stream?topic=/d400/color/image_raw&type=vp8";
export const TEST_STREAM_INSPECTION:string = "http://60.52.113.101:8080/stream?topic=/d400/depth/image_rect_raw&type=vp8";

//Websocket urls

export const BASE_WEBSOCKET = process.env.REACT_APP_BASE_WEBSOCKET_HOSTNAME ?? 'host.docker.internal';
export const ROS_HOSTNAME = process.env.REACT_APP_ROS_HOSTNAME ?? 'host.docker.internal' 
// export const ROBOT_URL = process.env.REACT_APP_ROBOT_HOSTNAME ?? 'host.docker.internal';
export const ROBOT_URL = 'host.docker.internal'
export const ROS_PORT = process.env.REACT_APP_ROS_PORT ?? 9090;
console.log(process.env);


export const WEBSOCKET_RTC:string = wsProtocol+BASE_WEBSOCKET+":8555/stream.html?src=windows_webcam&mode=webrtc";

// export const WEBSOCKET_URL:string = wsProtocol + ROS_HOSTNAME +(process.env.REACT_APP_ROS_PORT ?? ':9090');
export const WEBSOCKET_URL:string = "wss://mercury.wrybillrobotics.com/ros/";

export const FRONT_CAM: string = "ros_image:/front_camera/color/image_raw";
export const REAR_CAM: string = "ros_image:/rear_camera/color/image_raw";


// export const WEB_RTC_SERVER: string = "http://"+'m1'+":1984";
export const WEB_RTC_SERVER: string = process.env.REACT_APP_WEB_RTC_SERVER ?? "http://"+BASE_WEBSOCKET+":1984";
export const WEB_RTC_SERVER_USR: string = process.env.REACT_APP_WEB_RTC_SERVER_USR ?? "";
export const WEB_RTC_SERVER_PSWD: string = process.env.REACT_APP_WEB_RTC_SERVER_PSWD ?? "";

export const PTZ_SERVER: string =  window.location.protocol+'//'+BASE_WEBSOCKET+'/ptz_ctl';
// export const PTZ_SERVER: string =  window.location.protocol+'//'+BASE_WEBSOCKET+':3001';

export const AUTHREQ: boolean = process.env.REACT_APP_AUTHREQ === 'false' ? false : true;

export const OPERATOR_STATUS: boolean = process.env.REACT_APP_ADVANCED_OPERATOR === 'true' ? true : false ;
export const IS_OBSERVER: boolean = false;

OPERATOR_STATUS ? console.log("Operator is Advanced") : console.log("Operator is Basic")
const baseUrl = process.env.REACT_APP_BASE_URL ?? 'localhost:8000';
const location = process.env.REACT_APP_LOCATION?.split(',') ?? ['-40.380053', '175.612618']
export const MAP_CENTER = location.map(item => parseFloat(item));

console.log(location, MAP_CENTER);

export const REALTIME_UPDATES_URL = process.env.NODE_ENV === 'production' ? `${wsProtocol}` + baseUrl + "/api/ws/notify" : wsProtocol+baseUrl+"/api/ws/notify";
export const BASE_URL = process.env.NODE_ENV === 'production' ? 'https://' + baseUrl : window.location.protocol+'//'+baseUrl;
export const MEDIA_URL = process.env.NODE_ENV === 'production' ? 'https://' + baseUrl : window.location.protocol+'//'+baseUrl;
export const VERIFICATION_PASS = process.env.REACT_APP_VERIFICATION_PASS ?? 'your-secret-key';


const LINEAR_STRING =  process.env.REACT_APP_LINEAR ?? "0.4"
const ANGULAR_STRING =  process.env.REACT_APP_ANGULAR ?? "0.8"
export const LINEAR_SPEED: number = parseFloat(LINEAR_STRING)
export const ANGULAR_SPEED: number = parseFloat(ANGULAR_STRING)



console.log(REALTIME_UPDATES_URL);
console.log(WEBSOCKET_URL)
console.log(wsProtocol);
console.log(BASE_URL);
console.log(MEDIA_URL);

export const DISABLED = 0;
export const KEYBOARD = 1;
export const GAMEPAD = 2;

export const MAX_BATTERY = 52;
export const MIN_BATTERY = 48;
export const BATTERY_RANGE = MAX_BATTERY - MIN_BATTERY;
export const CURRENT_RANGE = 15;

export const MAP_OPTIONS = {
    center: {},
    zoom : 18,
    mapTypeId : 'satellite',
    disableDefaultUI: true,
    KeyboardEvent
};
