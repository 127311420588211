import { MouseEventHandler } from 'react';
import { Button } from 'react-bootstrap';
import { AssetSelect } from './AssetSelect';


interface Props {
    onSelect : (id : string) => void;       //Callback to handle the selection of a mission plan.
    onSearch : MouseEventHandler;              //Callback to run to run the mission plan.
    isChecked : boolean;
    onChange: (checked: boolean) => void;
}

//@ts-ignore
export const ImageViewerControlBanner = ({ onSelect, onSearch,isChecked, onChange} : Props) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.checked);
      };
    return (
        <div className='mission-planning-banner p-3 mb-4 bg-white rounded' >
            <span className='text-dark h5'>Asset Picker</span>
            <AssetSelect onSelect={onSelect}/>
            <label className="checkbox-label">
            <input
                type="checkbox"
                checked={isChecked}
                onChange={handleChange}
                className="checkbox-input"
            />
            Wideshot
            </label>
            <br/>
            <div className='d-flex'>
                <Button variant="primary" onClick={onSearch} className="flex-fill me-2">
                    Search
                </Button>
            </div>
        </div>
    );
}