import { memo } from 'react';
import '../../css/WarningOverlay.css';
import { useMsg } from "../Ros/Subscriber";

interface BatteryMessage {
    percentage : number;
    voltage: number;
    current: number;
}

const parseBatteryVoltage = (message: BatteryMessage) => {
    return message.voltage;
}

const BatteryWarningOverlayMemo = () => {
    const msg = useMsg();
    const parsedVoltageMessage = parseBatteryVoltage(msg as BatteryMessage);
    let showWarning = false;
    let warnMessage = "";
    let backgroundColor = "";

    if (parsedVoltageMessage <= 48) {
        showWarning = true;
        warnMessage = "Warning - Battery Level Low";
        backgroundColor = "rgba(255, 0, 0, 0.7)"; // Red
    } else {
        showWarning = false;
        warnMessage = "Battery Level Normal";
        backgroundColor = "rgba(0, 255, 64, 0.7)"; // Green
    }

    return (
        <>
            {showWarning && (
                <div className="battery-warning-overlay" style={{ backgroundColor }}>
                    <p>{warnMessage}</p>
                </div>
            )}
        </>
    );
};

export const BatteryWarningOverlay = memo(BatteryWarningOverlayMemo);
