import React, { createContext, useState, useContext, useEffect } from 'react';
import { useFetch } from '../services/Requests/useFetch';
import { useObserver } from './http/routing/ObserverContext';

// Create a context for backend time offset
const BackendTimeOffsetContext = createContext();

// Custom hook to use the BackendTimeOffsetContext
export const useBackendTimeOffset = () => {
  const context = useContext(BackendTimeOffsetContext);
  if (!context) {
    throw new Error('useBackendTimeOffset must be used within a BackendTimeOffsetProvider');
  }
  return context;
};

// Provider component to manage backend time offset
export const BackendTimeOffsetProvider = ({ children }) => {
  const fetchbackendTime = useFetch('/api/missions/sync_time', { method: 'GET' });
  const [backendTimeOffset, setBackendTimeOffset] = useState(null);
  const {isObserver} = useObserver()
  const syncBackendTime = () => {
    fetchbackendTime.get();
  };
  
    useEffect(() => {
      // Initial fetch
      if (!isObserver){
        syncBackendTime();

        // Set up interval to fetch backend time regularly
        const interval = setInterval(syncBackendTime, 10000); // Update every 10 seconds

        return () => {
          clearInterval(interval);
          fetchbackendTime.abortRequest && fetchbackendTime.abortRequest();
        };
      }
    }, []);


  useEffect(() => {
    const { state: { status, data } } = fetchbackendTime;

    if (status === 'fetched' && data && data.length > 0) {
      try {
        const [secsStr, nsecsStr] = data.split('.');
        const backendSecs = parseInt(secsStr, 10);
        const backendNsecs = parseInt(nsecsStr, 10);

        if (isNaN(backendSecs) || isNaN(backendNsecs)) {
          throw new Error('Invalid backend time values');
        }

        const backendTimeMillis = backendSecs * 1000 + Math.floor(backendNsecs / 1e6);
        const frontendTimeMillis = Date.now();
        const offset = backendTimeMillis - frontendTimeMillis;
        // console.log(`RAW BACKEND ${data} | BACKEND ${backendTimeMillis}  Frontend ${frontendTimeMillis}`);

        // console.log(`Offset calculated: ${offset}ms`);
        setBackendTimeOffset(offset);
      } catch (error) {
        console.error('Failed to sync backend time:', error);
        setBackendTimeOffset(null);
      }
    }
  }, [fetchbackendTime.state]);

  return (
    <BackendTimeOffsetContext.Provider value={backendTimeOffset}>
      {children}
    </BackendTimeOffsetContext.Provider>
  );
};
