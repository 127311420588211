import { useRef, useEffect, FC, FormEvent, useState } from "react";
import { Topic } from "roslib";
import { useConnection } from "../../../services/Ros/Connection";
import { accurateIntervalTimer, AccurateTimer } from "../../../utilities/utiltyFunctions";
//import { publish, buildTopicOptions } from "../../../services/Ros/utils/rosTopicCommands";
import { drive, setDeadzone } from "../../../services/Ros/Driving/drivingCommands";

interface IDriveGamepad {
    topic : Topic;
    timeOffset : number | null;
}


export const DriveGamepad : FC<IDriveGamepad> = ({topic,timeOffset}) => {

    const connected = useConnection();

    const pollingInterval = useRef<AccurateTimer>(() => {});
    const [ speedMultiplier, setSpeedMultiplier ] = useState<number>(0.5);
    console.log(timeOffset)

    const handleSpeedSliderChange = (e : FormEvent<HTMLInputElement>) => {
        setSpeedMultiplier(parseFloat(e.currentTarget.value));
    }

    useEffect(() => {
        if(connected === false){
            return;
        } 

        pollingInterval.current = accurateIntervalTimer(() => {

            const gp = navigator.getGamepads()[0];
            if(!gp || gp === null){
                return;
            }

            const deadZone = 0.15;                          // Set a deadzone so the robot doesn't drift randomly when the controller is 'sitting still'

            let x = gp.axes[0] * -1;                        // Get the left joystick x value
            let y = gp.axes[1] * -1;                        // Get the left joystick y value


            [x, y] = setDeadzone(x, y, deadZone);           // Setup smooth deadzone transitioning.

            x = x * speedMultiplier;                        //Multiply by the speed slider values.
            y = y * speedMultiplier;                        

            //console.log("Speeds: ", [x, y]);

            //We only want to publish a drive event when we have drive commands.
            let stamped = false;
        if (topic.name === '/cmd_vel_dashboard' || topic.name === '/cmd_vel_override'){
            stamped = true;
        }
            drive(topic,{ x:y, y:0, z:0},{o:0, a:0, t:x},stamped,timeOffset);
            
        }, 100);

        return () => {
            if(pollingInterval.current){
                pollingInterval.current();
            }
        }

    }, [connected, speedMultiplier]);

    return (
        <>
            <input type="range" min="0.1" max="1" step="0.1" className="gamepad-speed slider" value={speedMultiplier} onChange={handleSpeedSliderChange}/>
        </>
    );
}