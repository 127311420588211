import { createContext, useContext, useEffect, useState, FC } from "react";
import { useRos } from "./utils/rosProvider";
import { Service, ServiceRequest } from 'roslib';

interface IServiceCaller {
    name: string;
    type: string;
}

const ServiceContext = createContext<{ 
    callService: (request: ServiceRequest) => Promise<any>;
    unadvertiseService: () => void;
} | undefined>(undefined);

export const ServiceCaller: FC<IServiceCaller> = ({ children, name, type }) => {
    const ROS = useRos();
    const [service, setService] = useState<Service | null>(null);
    

    useEffect(() => {
        const rosService = new Service({
            ros: ROS,
            name,
            serviceType: type
        });
        setService(rosService);

        // Cleanup by unadvertising the service when the component unmounts
        return () => {
            console.log('Cleaning up service');
            rosService.unadvertise();
            setService(null);
        };
    }, [ROS, name, type]);

    const callService = async (request: ServiceRequest) => {
        if (!service) throw new Error('Service not available');

        return new Promise((resolve, reject) => {
            service.callService(request, (result) => {
                console.log('Service call succeeded:', result);
                service.unadvertise(); // Unadvertise on success
                console.log("unadvertised")
                resolve(result);
            }, (error) => {
                console.error('Service call failed:', error);
                service.unadvertise(); // Unadvertise on error
                reject(error);
            });
        });
    };

    const unadvertiseService = () => {
        if (service) {
            console.log('Manually unadvertising service');
            service.unadvertise();
            setService(null);
        }
    };

    return (
        <ServiceContext.Provider value={{ callService, unadvertiseService }}>
            {children}
        </ServiceContext.Provider>
    );
};

export const useServiceCaller = () => {
    const context = useContext(ServiceContext);
    if (context === undefined) {
        throw new Error('useServiceCaller must be used within a ServiceCaller provider');
    }
    return context;
};
