import { Topic } from 'roslib';
import { DriveKeyboard } from './components/DriveKeyboard';
import { DriveGamepad } from './components/DriveGampepad';
import { useDrivingMethod, DrivingTypes } from '../../services/Ros/Driving/DrivingProvider';
import { TEST_TOPIC } from '../../constants/topics';
import { buildTopicOptions } from '../../services/Ros/utils/rosTopicCommands';
import { useRos } from '../../services/Ros/utils/rosProvider';
import { DriveNipple } from './components/DriveNipple';
import { useBackendTimeOffset } from '../../utilities/BackendTimeOffsetProvider ';

export const DrivingControls = () => {
    const timeOffset = useBackendTimeOffset()
    const { 
        AUTOMATED, 
        GAMEPAD, 
        KEYBOARD, 
        NIPPLE 
    } = DrivingTypes;

    const { control, disabled,speedFactor } = useDrivingMethod();
    const ROS = useRos();
    const topic: Topic = new Topic(
        buildTopicOptions(ROS, TEST_TOPIC.name, TEST_TOPIC.type, TEST_TOPIC.throttle)
    );

    const generateDrivingMethod = () => {
        let controller = null;
        let controller2 = null;
    
        switch (control) {
            case AUTOMATED:
                controller = <></>;
                controller2 = <></>;
                break;
            case GAMEPAD:
                controller = <DriveGamepad key="gamepad" topic={topic} timeOffset={timeOffset}/>;
                break;
            case KEYBOARD:
                controller = <DriveKeyboard key="keyboard" topic={topic} timeOffset={timeOffset} speedFactor={speedFactor}/>;
                break;
            case NIPPLE:
                controller = (
                    <DriveNipple 
                        key="nippleleft" 
                        topic={topic} 
                        id="nippleleft" 
                        position="left" 
                        size={170}
                        containerHeight={170}
                        containerWidth={170}
                        timeOffset={timeOffset}
                    />
                );
                controller2 = (
                    <DriveNipple 
                        key="nippleright" 
                        topic={topic} 
                        id="nippleright" 
                        position="right" 
                        size={170}
                        containerHeight={170}
                        containerWidth={170}
                        timeOffset={timeOffset}
                    />
                );
                break;
            default:
                break;
        }
    
        return [controller, controller2].filter(Boolean);
    };
    

    const drivingMethod = disabled === false ? generateDrivingMethod() : null;

    return (
        <>
            {drivingMethod}
        </>
    );

}
