import React from 'react';

import { StatusContainer } from '../StatusBar/Containers/StatusContainer';
//import { RobotConnectionInfo } from './Connection/RobotConnectionInfo';
import { RobotConnectionIndicator } from './Connection/RobotConnectionIndicator';
//import { RobotVital } from './Vitals/RobotVital';
import { VitalContainer } from './Containers/VitalContainer';
import { BATTERY_TOPIC, CARDINAL,  STATE } from '../../constants/topics';
import { Subscriber } from '../../services/Ros/Subscriber';
import { BatteryIndicator } from './Indicators/BatteryIndicator';
// import { DiagnosticsIndicator } from './Indicators/DiagnosticsIndicator';
import { RobotIndicator } from './Indicators/RobotIndicator';
import { RobotCompass } from './Indicators/RobotCompass';
import { ServiceCaller } from '../../services/Ros/ServiceCaller';
// import { RobotVital } from './Vitals/RobotVital';
//import { TestIndicator } from './Indicators/TestIndicator';

export interface StatusBarProps{
    width : string;
}

/**
 * 
 * @param width - The width of the vital container
 * @returns 
 */
export const StatusBar: React.VFC<StatusBarProps> = ({width}) => {

    return(
            <StatusContainer className="status-container-bottom-centre">
                <VitalContainer style={{width : width}} >
                    <Subscriber name={BATTERY_TOPIC.name} type={BATTERY_TOPIC.type} rate={BATTERY_TOPIC.throttle}>
                        <BatteryIndicator name="Battery" unit="V"/>
                    </Subscriber>
                    <Subscriber name={BATTERY_TOPIC.name} type={BATTERY_TOPIC.type} rate={BATTERY_TOPIC.throttle}>
                        <BatteryIndicator name="Charge" unit="A"/>
                    </Subscriber>
                        {/* <RobotVital name="Ping" unit="ms" progress={-1}/> */}
                </VitalContainer>
                <VitalContainer style={{width : width}}>
                    <Subscriber name={STATE.name} type={STATE.type} rate={STATE.throttle}>
                        <ServiceCaller name="/state_request_service" type="std_srvs/srv/Trigger">
                            <RobotIndicator name="State" />
                        </ServiceCaller>
                    </Subscriber>
                    <Subscriber name={CARDINAL.name} type={CARDINAL.type} rate={CARDINAL.throttle}>
                        {/* <DiagnosticsIndicator name="CPU" unit="%" searchTerm='CPU usage'/> */}
                        {/* <DiagnosticsIndicator name="Disk" unit="%" searchTerm='Disk Usage'/> */}
                        <RobotCompass name="Cardinal"/>
                    </Subscriber>
                </VitalContainer>
                <RobotConnectionIndicator />
                {/* <RobotConnectionInfo wifi={-90}/> */}
            </StatusContainer>
    );
}