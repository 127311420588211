import React from 'react';
import { ServiceRequest } from 'roslib';
import { ServiceCaller, useServiceCaller } from '../../services/Ros/ServiceCaller';
import { Button } from 'react-bootstrap';

const StopAutodockButton: React.FC = () => {
    // Destructure callService and unadvertiseService from the context
    const { callService,  } = useServiceCaller();

    // Function to handle the button click
    const handleStopAutodock = async () => {
        const request: ServiceRequest = {};  // Add any specific request fields here if needed

        try {
            const result = await callService(request);
            console.log('Service call successful:', result);
        } catch (error) {
            console.error('Service call failed:', error);
        } finally {
            // unadvertiseService(); // Ensure the service is unadvertised after the call completes
            console.log("Don't Manually Unadvertise")
        }
    };

    return (
        <Button variant="danger" onClick={handleStopAutodock}>
            Cancel Docking
        </Button>
    );
};

export const AutodockStopService: React.FC = () => {
    return (
        // Provide the /autodock/stop service
        <ServiceCaller name="/lidar_docking/stop_docking" type="std_srvs/srv/Trigger">
            <StopAutodockButton />
        </ServiceCaller>
    );
};
