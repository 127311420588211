import { Topic } from 'roslib';
import { useRef, useEffect, FC, useState } from 'react';
import { useConnection } from '../../../services/Ros/Connection';
import { driveWithLoop, terminate } from '../../../services/Ros/Driving/drivingCommands';
import { useShortcutsStatus } from '../../../services/General/KeyboardShortcutProvider';
import { STAMPED, TEST_TOPIC } from '../../../constants/topics';
import { ANGULAR_SPEED, LINEAR_SPEED } from '../../../constants/constants';


interface KeyboardAction {
    pressed : boolean;
    // lockedKeys : string[];
    // begin : () => void;
}

interface KeyboardControllerInterface {
    [name : string] : KeyboardAction 
}

interface IDriveKeyboard {
    topic : Topic;
    timeOffset : number | null;
    speedFactor : number;
}

// const M_PER_S = 0.3;
// const R_PER_S = 0.6;


export const DriveKeyboard : FC<IDriveKeyboard> = ({topic,timeOffset,speedFactor}) => {

    const connected = useConnection();      //Check the connecttion status of Ros.
    const { enabled }= useShortcutsStatus();
    const [M_PER_S, setM_PER_S] = useState<number>(LINEAR_SPEED*speedFactor)
    const [R_PER_S, setR_PER_S] = useState<number>(ANGULAR_SPEED*speedFactor)


    useEffect(() => {
        setM_PER_S(LINEAR_SPEED*speedFactor)
        setR_PER_S(ANGULAR_SPEED*speedFactor)
    }, [speedFactor]);
    
    const interval = 100;
 
    console.log(`First in Keyboard: ${timeOffset}`);

    //const robotController = useRef<RobotController>(new RobotController(connected, topic));
    const clearTimer = useRef<(() => void) | null | undefined>(null);

    const keyboardController:KeyboardControllerInterface = {
        'w' : { pressed : false },
        'a' : { pressed : false },
        's' : { pressed : false },
        'd' : { pressed : false },
        'e' : { pressed : false },
    };

    const drive = (): void => {
        //Software emergency stop
        // console.log(`Drive in Keyboard ${timeOffset}`)
        
        // const M_PER_S = LINEAR_SPEED*speedFactor;
        // const R_PER_S = ANGULAR_SPEED*speedFactor;
        // console.log(`Before Factor ${LINEAR_SPEED},${ANGULAR_SPEED} | After Factor ${M_PER_S},${R_PER_S} | Speed Factor ${speedFactor}`)

        if(keyboardController['e'].pressed === true){
            let stamped = true;
            if (topic.name === TEST_TOPIC.name || topic.name === STAMPED.name){
                stamped = true;
            }
            terminate(topic,stamped,timeOffset);
            Object.keys(keyboardController).forEach(key => {
                keyboardController[key].pressed = false;
            });
        } else if(keyboardController['w'].pressed && keyboardController['s'].pressed){
            return;
        } else if (keyboardController['a'].pressed && keyboardController['d'].pressed){
            return;
        } else if(keyboardController['w'].pressed && keyboardController['a'].pressed){
            //robotController.current.forwardLeft();
            driveLoop(M_PER_S, 0, 0, 0, 0, R_PER_S);
            return;
        } else if (keyboardController['w'].pressed && keyboardController['d'].pressed){
            //robotController.current.forwardRight();
            driveLoop(M_PER_S, 0, 0, 0, 0, -R_PER_S);
            return;
        } else if (keyboardController['s'].pressed && keyboardController['a'].pressed){
            //robotController.current.backLeft();
            driveLoop(-M_PER_S, 0, 0, 0, 0, R_PER_S);
            return;
        } else if (keyboardController['s'].pressed && keyboardController['d'].pressed){
            //robotController.current.backRight();
            driveLoop(-M_PER_S, 0, 0, 0, 0, -R_PER_S);
            return;
        } else if (keyboardController['w'].pressed){
            //robotController.current.forward();
            driveLoop(M_PER_S,0,0,0,0,0);
            return;
        } else if (keyboardController['a'].pressed){
            //robotController.current.left();
            driveLoop(0,0,0,0,0,R_PER_S);
            return;
        } else if (keyboardController['s'].pressed){
            //robotController.current.backward();
            driveLoop(-M_PER_S, 0, 0, 0, 0, 0);
            return;
        } else if (keyboardController['d'].pressed){
            //robotController.current.right();
            driveLoop(0, 0, 0, 0, 0, -R_PER_S);
            return;
        } else {
            return;
        }
    }

    const driveLoop = (x : number, y : number, z : number, o : number, a : number, t : number) => {
        clearOldTimer();
        let stamped = true;
        if (topic.name === TEST_TOPIC.name || topic.name === STAMPED.name){
            stamped = true;
        }
        console.log(`Driveloop in Keyboard ${timeOffset}`)
        clearTimer.current = driveWithLoop(topic,{x,y,z},{o,a,t}, interval,stamped,timeOffset);
    }

    const clearOldTimer = () => {
        if(clearTimer.current){
            clearTimer.current();   //Clear any timer loop currently running.
        }
    }

    /**
    * This function handles a key down event by setting the pressed value for the key object to true.
    * @param e Keyboard event
    */
    const handleKeyDown = (e: KeyboardEvent): void => {
        if(e.repeat){return}                                    //Make sure when the key is held down it doesn't fire hundreds of keydown events.
        if(keyboardController[e.key]){
            keyboardController[e.key].pressed = true;
            drive();
        }
    }

    /**
     * This function handles a key up event by setting the pressed value for the key object to false, diasabling any functions running while the key is pressed.
     * @param e Key up event
     */
    const handleKeyUp = (e: KeyboardEvent): void =>{
        let count = 0;
        if(keyboardController[e.key]){
            keyboardController[e.key].pressed = false;
            clearOldTimer();
            Object.keys(keyboardController).forEach(key => {
                keyboardController[key].pressed && count++;
            });
            if(count === 0){
                let stamped = true;
                if (topic.name === TEST_TOPIC.name || topic.name === STAMPED.name){
                    stamped = true;
                }
                console.log(`Timeout in Keyboard ${timeOffset}`)
                terminate(topic,stamped,timeOffset);
            } else {
                drive();
            }
        }
    }


    useEffect(() => {
        if(connected === false){
            return;
        }

        if(enabled === false){
            return;
        }
        
        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('keyup', handleKeyUp);
        
        return () => {
            clearOldTimer();
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('keyup', handleKeyUp);
        }
    }, [connected, enabled]);

    return <></>;
}