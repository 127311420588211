import { useState, useEffect, CSSProperties } from 'react';
import { useShortcutsStatus } from '../../services/General/KeyboardShortcutProvider';
import { useDrivingMethod } from '../../services/Ros/Driving/DrivingProvider';

interface PathPlanningOverlayProps {
    isManualModeActive: boolean;
}

export const SpeedControl = ({ isManualModeActive }: PathPlanningOverlayProps)  => {
    const { enabled } = useShortcutsStatus();
    const { speedFactor, setSpeedFactor } = useDrivingMethod(); // Assuming `setSpeedFactor` is provided in context.
    const [localSpeedFactor, setLocalSpeedFactor] = useState(speedFactor);

    const handleKeyDown = (e: KeyboardEvent) => {
        if (!enabled) return;

        if (e.key === 'ArrowUp') {
            setLocalSpeedFactor((prev) => Math.min(1, prev + 0.1));
        } else if (e.key === 'ArrowDown') {
            setLocalSpeedFactor((prev) => Math.max(0, prev - 0.1));
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [enabled]);

    useEffect(() => {
        setSpeedFactor(localSpeedFactor);
    }, [localSpeedFactor, setSpeedFactor]);

    return (
        <div style={styles.card}>
            <h3 style={styles.header}>Manual Speed Control</h3>
            {/* <div style={styles.display}>
                <span style={styles.speedLabel}>Speed:</span>
                <span style={styles.speedValue}>{(localSpeedFactor * 100).toFixed(0)}%</span>
            </div> */}
            <input
                type="range"
                min={0}
                max={1}
                step={0.01}
                value={localSpeedFactor}
                onChange={(e) => setLocalSpeedFactor(Number(e.target.value))}
                style={styles.slider}
                disabled={isManualModeActive}
            />
            {/* <div style={styles.instructions}>
                <p>Use <b>Arrow Up</b> to increase speed</p>
                <p>Use <b>Arrow Down</b> to decrease speed</p>
            </div> */}
        </div>
    );
};

const styles: Record<string, CSSProperties> = {
    card: {
        width: '240px',
        padding: '10px',
        borderRadius: '10px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        backgroundColor: '#f9f9f9',
        textAlign: 'center',
        fontFamily: 'Arial, sans-serif',
    },
    header: {
        margin: '0 0 10px',
        fontSize: '18px',
        color: '#333',
    },
    display: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '10px',
    },
    speedLabel: {
        marginRight: '10px',
        fontSize: '16px',
        fontWeight: 'bold',
        color: '#555',
    },
    speedValue: {
        fontSize: '20px',
        fontWeight: 'bold',
        color: '#007bff',
    },
    slider: {
        width: '100%',
        margin: '10px 0',
    },
    instructions: {
        fontSize: '12px',
        color: '#777',
    },
};
