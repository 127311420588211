import { memo } from "react";
import "../../css/CollisionOverlay.css";
import { useMsg } from "../../services/Ros/Subscriber";

interface CollisionMessage {
  polygons: string[];
  detections: boolean[];
}

interface CollisionOverlayProps {
  direction?: string;
  stream: string;
}

const parseMessage = (message: CollisionMessage) => {
  return message;
};

const CollisionOverlayMemo: React.FC<CollisionOverlayProps> = ({
  stream,
}) => {
  const msg = useMsg();
  const parsedCollisionMessage = parseMessage(msg as CollisionMessage);

  const { polygons, detections } = parsedCollisionMessage;
  // polygons = ["Front","Left","Right","Rear"]
  // detections = [true,true,true,true]
  // console.log(polygons,detections)
  
  // Display the first 4 warnings if detections are true
  let collisionMessages: string[] = [];
  let positions: string[] = [];
  // let backgroundColor = "rgba(255, 0, 0, 0.7)"; // Red background color for warnings

  if (polygons && detections){
  for (let i = 0; i < Math.min(4, polygons.length); i++) {
    if (detections[i]) {
      const direction = polygons[i].replace("Polygon", "");
      collisionMessages.push(`Obstruction ${direction}`);
      // Determine position based on stream and direction
      let position: string = "top";

      if (stream === "front" || stream === "ptz") {
        if (direction === "Front") {
          position = "ctop";
        } else if (direction === "Left") {
          position = "cleft";
        } else if (direction === "Right") {
          position = "cright";
        } else {
          position = "cbottom";
        }
      } else if (stream === "rear") {
        if (direction === "Front") {
          position = "cbottom";
        } else if (direction === "Left") {
          position = "cright"; // Reverse for rear
        } else if (direction === "Right") {
          position = "cleft"; // Reverse for rear
        } else {
          position = "ctop";
        }
      }
      positions.push(position);
    }
  }

  return (
    <>
      {collisionMessages.map((message, index) => (
        <div
          key={index}
          className={`collision-overlay ${positions[index]}`}
        >
          <p>{message}</p>
        </div>
      ))}
    </>
  );
}else{
    return (
        <></>
    )
}
};

export const CollisionOverlay = memo(CollisionOverlayMemo);
