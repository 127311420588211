import { useEffect, useRef, useState } from "react";
import { useFetch } from "../services/Requests/useFetch";

const options: RequestInit = {
    method: "GET"
};

export const LogViewer = () => {
    const logs = useFetch<{ log: string, available_logs: string[] }>('/api/missions/get_log_file', options);

    const [logData, setLogData] = useState<string[]>([]);
    const [availableLogs, setAvailableLogs] = useState<string[]>(["app.log"]);
    const [selectedLog, setSelectedLog] = useState<string>("app.log");
    const [logsCache, setLogsCache] = useState<Record<string, string[]>>({}); // Cache for logs

    const logContainerRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        logs.get(); // Fetch available logs on mount

        return () => {
            logs.abortRequest && logs.abortRequest();
        };
    }, []);

    // Fetch log data for a specific log if not cached
    useEffect(() => {
        const fetchLogData = async (logName: string) => {
            // Fetch log data if it's not cached
            const { state: { status, data } } = logs;
            if (status === 'fetched' && data) {
                const logNames = data['available_logs'];
                const logsArray = data['log'].split('\n').filter(log => log.trim() !== '').reverse();
                if (logName !== "app.local"){
                    setLogsCache((prev) => ({ ...prev, [logName]: logsArray }));
                }
                setLogData(logsArray);
                setAvailableLogs(logNames);
            }


        };

        fetchLogData(selectedLog); // Fetch the selected log data
    }, [logs.state.data]);

    const handleLogSelection = (logName: string) => {
        setSelectedLog(logName);
        if (logsCache[logName]) {
            setLogData(logsCache[logName]);
            return;
        }
        // If the log is not cached, make the API call
        logs.get([{ param: 'select_log', value: logName }]); // Assuming useFetch supports queryParams
        return () => {
            logs.abortRequest && logs.abortRequest();
        };

    };

    const handleDownloadLog = () => {
        const logContent = logData.reverse().join('\n');
        const blob = new Blob([logContent], { type: "text/plain" });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${selectedLog}.txt`;
        a.click();
        URL.revokeObjectURL(url);
    };

    const scrollToBottom = () => {
        if (logContainerRef.current) {
            logContainerRef.current.scrollTop = logContainerRef.current.scrollHeight;
        }
    };

    const scrollToTop = () => {
        if (logContainerRef.current) {
            logContainerRef.current.scrollTop = 0;
        }
    };

    return (
        <div>
            <h2>Log Viewer</h2>
            <div style={{
                display: 'flex',
                borderBottom: '1px solid #ccc',
                marginBottom: '10px',
                maxWidth: '80%'
            }}>
                <select value={selectedLog} onChange={(e) => handleLogSelection(e.target.value)} style={{ padding: '10px 15px', cursor: 'pointer', backgroundColor: '#f9f9f9', border: '1px solid #007bff', borderRadius: '4px', }} >
                {availableLogs.map((logName) => (
                    <option
                        key={logName}
                        value={logName}
                        style={{
                            padding: '10px 15px', backgroundColor: selectedLog === logName ? '#ddd' : '#f9f9f9', fontWeight: selectedLog === logName ? 'bold' : 'normal',
                        }}
                    >
                        {logName}
                    </option>
                ))}
                </select>
            </div>
            {logData.length > 0 ? (
                <div
                    ref={logContainerRef}
                    style={{
                        width: '100%',
                        height: '20vh',
                        overflowY: 'auto',
                        border: '1px solid #ccc',
                        padding: '10px',
                        backgroundColor: '#f9f9f9',
                    }}
                >
                    {logData.map((log, index) => (
                        <div
                            key={index}
                            style={{
                                marginBottom: '10px',
                                whiteSpace: 'pre-wrap',
                                fontFamily: '"Courier New", Courier, monospace',
                                fontSize: '14px',
                                color: '#333',
                            }}
                        >
                            {log}
                        </div>
                    ))}
                </div>
            ) : (
                <p>No logs available</p>
            )}
            <div style={{ marginTop: "10px" }}>
                <button onClick={handleDownloadLog} style={{ marginRight: "10px" }}>
                    Download Log
                </button>
                <button onClick={scrollToBottom} style={{ marginRight: "10px" }}>
                    &#x2B07; Scroll to Bottom
                </button>
                <button onClick={scrollToTop}>
                    &#x2B06; Scroll to Top
                </button>
            </div>
        </div>
    );
};
