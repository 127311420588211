import { FC, useEffect, useState } from "react";
import { useMsg } from "../../services/Ros/Subscriber";
import { ServiceRequest } from "roslib";
import { useServiceCaller } from "../../services/Ros/ServiceCaller";

interface RobotStateMessage {
    data: number;
}

interface IRobotStateIndicator { 
    name: string;
    onStateChange: (state: string) => void; // Callback to handle state change
}

const parseRobotState = (message: RobotStateMessage): string => {
    const stateMapping: Record<number, string> = {
        0: "IDLE",
        1: "UNDOCK",
        2: "READY",
        3: "DOCKING",
        4: "AUTO",
        5: "MANUAL",
        6: "NO GPS FIX",
        7: "UNDOCK"
    };

    const stateNumber = message.data;
    return stateMapping[stateNumber] || "UNKNOWN"; // Default to "UNKNOWN" if stateNumber is not in the mapping
}

export const RobotState: FC<IRobotStateIndicator> = ({ onStateChange }) => {
    const [parsedState, setParsedState] = useState<string>("UNKNOWN");
    const { callService } = useServiceCaller();
    const msg = useMsg();

    useEffect(() => {
        onStateChange(parsedState);
    }, [parsedState, onStateChange]);

    try {
        useEffect(() => {
            const fetchRobotState = async () => {
                const request: ServiceRequest = {};

                    const result = await callService(request);
                    if (result.success && result.message) {
                        setParsedState(parseRobotState({data: parseInt(result.message)} as RobotStateMessage))
                        console.log(result)
                    } else {
                        console.error("Service returned an unsuccessful response.");
                    }
                
            };

            fetchRobotState();
        }, [callService]);
    } catch (error) {
        console.error("Service call failed:", error);
    }

    useEffect(() => {
        setParsedState(parseRobotState(msg as RobotStateMessage))
    }, [msg]);

    return null; // No need to return any JSX since we're only handling state change
}
