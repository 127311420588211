import { memo, useEffect, useState, MouseEventHandler, ChangeEvent } from 'react';
import { Row, Col, Card, Image, Container, Spinner, Form, Button, Pagination } from 'react-bootstrap';
import { useFetch } from '../../services/Requests/useFetch';
import { ImageViewerControlBanner } from './ImageViewerControlBanner';
import { useObserver } from '../../utilities/http/routing/ObserverContext';
import { useOperator } from '../../utilities/http/routing/OperatorContext';

interface LocationTypes { 
    id: number;
    name: string;
    description: string;
    icon: string;
    icon_greyscale: string;
    icon_success: string;
}

interface MissionProgress {
    id: number;
    progress: number;
    running: boolean;
    last_run: string | null | undefined;
}

export interface MissionData {
    id: number;
    point_name: string;
    location_type: LocationTypes;
    asset_order: number;
    lat: string;
    lng: string;
    enabled: boolean; 
    mission_plan: MissionProgress;
}

export interface IMissionInfo {
    markers: MissionData[];
    prog: number;
    run: boolean;
    last_run?: string | null | undefined;
}

interface ImageData {
    id: string;
    url: string;
    title: string;
}

interface AssetInfo {
    zone: string;
    stop: string;
    asset_id: string;
    preset: string;
    missions: string[];
}

const options: RequestInit = {
    method: "GET"
}

interface DeleteImageParams {
    filename: string;
}

export const ImageViewerBodyMemo = () => {
    const { state: assetImagesState, get: getAssetImages } = useFetch<{ assets: ImageData[]; total_pages: number }>('/api/missions/get_images', options);
    const { state: assetInfoState, get: getAssetInfo } = useFetch<AssetInfo[]>('/api/missions/get_asset_info', options);
    const { state: changeAssetNameState, get: changeAssetName } = useFetch<null>('/api/missions/change_asset', { method: 'GET' });
    const [activeIndex, setActiveIndex] = useState<string>('');
    const [images, setImages] = useState<ImageData[]>([]);
    const [assetInfo, setAssetInfo] = useState<AssetInfo | null>(null);
    const [newAssetName, setNewAssetName] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState<number>(1); // Track current page
    const [totalPages, setTotalPages] = useState<number>(1);   // Track total pages
    const [checked, setChecked] = useState<boolean>(false);
    const { isObserver } = useObserver();
    const { isOperator } = useOperator();

    const getDeleteImage = useFetch<DeleteImageParams>('/api/missions/delete_image', options);

    const handleSelected = (id: string) => {
        let newId = id;
    
        if (checked) {
            // Add "WD" if not already present
            newId = id.endsWith("WD") ? id : `${id}WD`;
        } else {
            // Remove "WD" if present
            newId = id.endsWith("WD") ? id.slice(0, -2) : id;
        }
    
        setActiveIndex(newId);
    };
    

    useEffect(() => {
        handleSelected(activeIndex)
    }, [checked]);
    

    const handleRun: MouseEventHandler = (_e) => {
        setLoading(true);
        getAssetImages([{ param: 'asset', value: activeIndex }, { param: 'skip', value: (currentPage - 1).toString() }]);
        getAssetInfo([{ param: 'asset_id', value: activeIndex }]);
    }

    
    

    const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        let newAsset =   e.target.value
        .replace(/\s+/g, '') // Remove whitespace
        .replace(/[<>:"/\\|?*\x00-\x1F]/g, '') // Remove illegal characters for Windows
        .replace(/\.+$/, '') // Remove trailing periods (Windows restriction)
        .replace(/CON|PRN|AUX|NUL|COM[1-9]|LPT[1-9]/gi, '') // Remove reserved filenames
        
        if (newAsset.endsWith("WD") || newAsset.endsWith("LQ")){
            newAsset = newAsset.slice(0, -2);
        }
        
        setNewAssetName(
            newAsset
          );

    }

    const handleNameSubmit = () => {
        if (assetInfo) {
            // Remove "WD" if present
            const newId = assetInfo.asset_id.endsWith("WD") ? assetInfo.asset_id.slice(0, -2) : assetInfo.asset_id;
    
            changeAssetName([
                { param: 'zone', value: assetInfo.zone },
                { param: 'stop', value: assetInfo.stop },
                { param: 'asset', value: newId },
                { param: 'newAsset', value: newAssetName }
            ]);
        }
    }

    const handleDownload = (image: ImageData) => {
        const link = document.createElement('a');
        link.href = image.url;
        link.download = `${image.id || 'download'}`;
        link.click();
    };

    const handleDelete = async (image: ImageData) => {
        const confirmDelete = window.confirm(`Are you sure you want to delete the image titled "${image.id}"?`);

        if (confirmDelete) {
            try {
                await getDeleteImage.get([{ param: 'filename', value: image.id }]);
                setLoading(true);
                getAssetImages([{ param: 'asset', value: activeIndex }, { param: 'skip', value: (currentPage - 1).toString() }]);
                getAssetInfo([{ param: 'asset_id', value: activeIndex }]);
            } catch (error) {
                console.error('Failed to delete image', error);
            }
        }
    };

    useEffect(() => {
        const { state: { status, data } } = getDeleteImage;
        if (status === 'fetched' && data) {
            setLoading(true);
            getAssetImages([{ param: 'asset', value: activeIndex }, { param: 'skip', value: (currentPage - 1).toString() }]);
            getAssetInfo([{ param: 'asset_id', value: activeIndex }]);
        }
    }, [getDeleteImage.state]);

    useEffect(() => {
        const { status, data } = assetImagesState;
        if (status === 'fetched' && data) {
            setImages(data.assets);
            setTotalPages(data.total_pages);  // Set total pages from the response
            setLoading(false);
        }
    }, [assetImagesState]);

    useEffect(() => {
        const { status, data } = assetInfoState;
        if (status === 'fetched' && data && data.length > 0) {
            setAssetInfo(data[0]);
        }
    }, [assetInfoState]);

    useEffect(() => {
        if (changeAssetNameState.status === 'fetched') {
            setNewAssetName('');
            handleRun(null as any);
        }
    }, [changeAssetNameState]);

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
        setLoading(true);
        getAssetImages([{ param: 'asset', value: activeIndex }, { param: 'skip', value: (page - 1).toString() }]);
    };

    const isSnapshot = activeIndex === 'snapshot';

    const renderPaginationItems = () => {
        const pageItems = [];
        const pageLimit = 3; // Number of pages to show near current page

        // Calculate the start and end range for the page items
        const startPage = Math.max(currentPage - Math.floor(pageLimit / 2), 1);
        const endPage = Math.min(startPage + pageLimit - 1, totalPages);

        if (startPage > 1) {
            pageItems.push(<Pagination.Ellipsis key="ellipsis-start" />);
        }

        for (let page = startPage; page <= endPage; page++) {
            pageItems.push(
                <Pagination.Item
                    key={page}
                    active={page === currentPage}
                    onClick={() => handlePageChange(page)}
                >
                    {page}
                </Pagination.Item>
            );
        }

        if (endPage < totalPages) {
            pageItems.push(<Pagination.Ellipsis key="ellipsis-end" />);
        }
        if (activeIndex!==''){
            return pageItems;
        }
    };


    return (
        <Container fluid className='h-100'>
            <Row className='h-100'>
                <Col lg={9} className="ps-0">
                    <div className="mt-3 image-container">
                        <h1>Images</h1>
                        <div className="image-container" style={{ maxHeight: '80vh', overflowY: 'auto' }}>
                            {loading ? (
                                <div className="d-flex justify-content-center align-items-center" style={{ height: '100%' }}>
                                    <Spinner animation="border" />
                                </div>
                            ) : (
                                images.map((image, index) => (
                                    <Card key={index} className="mb-3">
                                        <Card.Body>
                                            <Card.Title>{image.title}</Card.Title>
                                            <div className="image-wrapper">
                                                <Image src={image.url} fluid thumbnail />
                                            </div>
                                            <Button variant="primary" onClick={() => handleDownload(image)}>
                                                Download Image
                                            </Button>
                                            <Button 
                                                variant="danger" 
                                                onClick={() => handleDelete(image)} 
                                                disabled={isObserver}
                                            >
                                                Delete Image
                                            </Button>
                                        </Card.Body>
                                    </Card>
                                ))
                            )}
                        </div>
                        
                    </div>
                </Col>
                <Col lg={3} className="mt-3 mb-3 overflow-overlay path-planning-column">
                    <ImageViewerControlBanner 
                        onSelect={handleSelected} 
                        onSearch={handleRun}
                        onChange={setChecked}
                        isChecked={checked}
                    />
                    {!isSnapshot && assetInfo ? (
                        <div className="mt-3">
                            <h5>Asset Information</h5>
                            <Card className="mb-3">
                                <Card.Body>
                                    <p><strong>Zone:</strong> {assetInfo.zone}</p>
                                    <p><strong>Stop:</strong> {assetInfo.stop}</p>
                                    <p><strong>Preset:</strong> {assetInfo.preset}</p>
                                    <p><strong>Missions:</strong> {assetInfo.missions.join(', ')}</p>
                                </Card.Body>
                            </Card>
                            {isOperator && (
                                <div className="mt-2">
                                    <Form.Group>
                                        <Form.Label>Rename Asset:</Form.Label>
                                        <Form.Control 
                                            type="text" 
                                            value={newAssetName} 
                                            onChange={handleNameChange} 
                                            placeholder="Enter new asset name" 
                                        />
                                        <Button 
                                            className="mt-2" 
                                            onClick={handleNameSubmit}
                                            disabled={!newAssetName}
                                        >
                                            Submit
                                        </Button>
                                    </Form.Group>
                                </div>
                            )}
                        </div>
                    ) : null}
                     {/* Pagination Controls */}
                     <Pagination className="mt-3">
                        <Pagination.First 
                            disabled={currentPage === 1} 
                            onClick={() => handlePageChange(1)}
                            hidden={activeIndex==''} 
                        />
                        <Pagination.Prev 
                            disabled={currentPage === 1} 
                            onClick={() => handlePageChange(currentPage - 1)}
                            hidden={activeIndex==''}  
                        />
                        {renderPaginationItems()}
                        <Pagination.Next 
                            disabled={currentPage === totalPages} 
                            onClick={() => handlePageChange(currentPage + 1)}
                            hidden={activeIndex==''}  
                        />
                        <Pagination.Last 
                            disabled={currentPage === totalPages} 
                            onClick={() => handlePageChange(totalPages)}
                            hidden={activeIndex==''}  
                        />
                    </Pagination>
                </Col>
            </Row>
        </Container>
    );
}

export const MemoizedImageViewerBodyMemo = memo(ImageViewerBodyMemo);


export const ImageViewerBody = memo(ImageViewerBodyMemo);
